@import '../variable';
.forgot-dialog{
  margin: 0px auto;
  padding: 110px 0 150px;
  width: 28rem;
  .form-input{
    .MuiTextField-root{
      margin: 40px 0;
    }
  }
}

.forgot-dialog.change-password {
  .form-input{
    .MuiTextField-root{
      margin: 20px 0;
    }
  }
}

.icon-close{
  text-align: right;
  padding: 15px;
}

.form-input{
  .success-field{
    .MuiFormHelperText-contained{
      color: $greenHelperText !important;
    }
    .MuiOutlinedInput-notchedOutline{
      border-color: $greenHelperText !important;
    }
    .Mui-error{
      color: $greenHelperText !important;
      input{
        color: #000 !important;
      }
    }
  }
  .error-field{
    .MuiFormHelperText-contained{
      color: $redError !important;
    }
  }
}
