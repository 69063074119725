@import '../variable';
.upload-container.preview-content-type{
  display: inline-block;
  flex-direction: unset;
  justify-content: unset;
  width: unset;
  height: unset;
  background: unset;
  border: unset;
  padding-top: 15px;
  text-align: left;
  .view{
    position: relative;
    width: 120px;
    height: 120px;
    display: inline-block;
  }
  .button-upload{
    margin-top: 10px;
    text-align: left;
  }
}
