@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

$mainDark: #262c2b;
$darkBlue: #2b4257;
$yellow: #faff1c;
$mainWhite: #fff;
$solidBlack: #000000;
$primaryFont: 'Poppins';
$lightGreen: #c4dd80;
$borderGray: #e0e0e0;
$greyFooterBottom: #7c7c7c;
$greySecondaryText: #757575;
$greyInputLabel: #333333;
$greyTNC: #585858;
$greyBorderUserType: #d9d9d9;
$blueFBButton: #1877f2;
$whiteMenuDrawer: #fafafa;
$greenHelperText: #00954d;
$borderMenuDrawer: rgba(255, 255, 255, 0.16);
$redError: #d32f2f;
