// header
.header {
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .logo {
        width: 139px;
        @media screen and (max-width: 767.98px) {
            width: 100px;
        }
    }
    .nav-link {
        color: #fff;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 17px;
        &.active {
            color: #C3DC80;
            text-decoration: underline;
        }
    }
}
.loading.zIndex999{
  z-index: 9999;
}

.spc-card{

  .spc-img {
    .MuiAvatar-root{
      min-width: 100%;
      min-height: 170px;
    }
  }
  .spc-desc{
    min-height: 120px;
  }
}

.header-second {
  background: #56615E;
  padding: 10px 30px;
}