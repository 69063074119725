.images-box {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    border-radius: 8px;
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.project-picture {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    align-items: center;
    flex-wrap: wrap;
    .picture-item {
        flex: 0 0 120px;
        max-width: 120px;
        padding: 5px;
    }
}



.profile-images {
    position: relative;
    width: 207px;
    padding-bottom: 201px;
    border-radius: 8px;
    overflow: hidden;
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


