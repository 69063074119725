.project-detail {
    color: #333333;
}

.project-photo {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    .photo-item {
        flex: 0 0 125px;
        max-width: 125px;
        padding: 5px;
    }
}

.file-container {
    width: 120px;
    height: 120px;
    display: inline-flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
}

.side-detail {
    background: #f9f9f9;
    border-radius: 0px 20px 20px 0px;
    height: 100%;
    padding: 20px 15px;
    h3 {
        font-weight: 600;
        font-size: 16px;
        color: #757575;
    }
}

.profile-project-detail {
    display: flex;
    .profile-img {
        flex: 0 0 80px;
        max-width: 80px;
    }
    .profile-role {
        margin-top: 5px;
    }
}