.user-dashboard {
  display: flex;
  height: 100vh;
  .sidebar {
    flex: 0 0 260px;
    max-width: 260px;
    background: #2b4257;
    overflow-x: hidden;
    overflow-y: auto;
    color: #fafafa;
    font-size: 14px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .btn-success {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .profile-img {
      width: 40px;
      height: 0;
      position: relative;
      border-radius: 8px;
      padding-bottom: 40px;
      overflow: hidden;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .profile-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
    .profile-reputation {
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      span {
        color: #fb8081;
      }
    }
    .sidebar-item {
      padding: 22px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      &:last-child {
        border-bottom: 0;
      }
    }
    .sidebar-logo {
      padding: 22px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      &:last-child {
        border-bottom: 0;
      }
      img {
        margin-left: -16px;
      }
    }
    .nav-sidebar {
      list-style: none;
      padding-left: 0;
      .nav-item {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #fafafa;
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
          color: #faff1c;
        }
        #aff1c .nav-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .badge {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #faff1c;
          color: #2b4257;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
        }
      }
    }
    .popper {
      position: absolute;
      width: 339px;
      z-index: 999;
      left: 56px;
      background: #fff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      color: #333;
      padding: 12px 20px;
      margin-top: 10px;
      &:before {
        position: absolute;
        content: '';
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 12px solid #fff;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
      .popper-title {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
      }
      .tag-preferences {
        .btn {
          font-size: 13px;
          padding-left: 10px;
          padding-right: 10px;
          margin-right: 10px;
          margin-top: 10px;
          &.btn-outline-grey:hover {
            background: #faff1c;
            color: #2b4257;
            border: 1px #faff1c solid;
          }
        }
      }

      .MuiSlider-rail {
        height: 4px;
        background-color: #d9d9d9;
      }
      .MuiSlider-track {
        height: 4px;
        background-color: #faff1c;
      }
      .MuiSlider-thumb {
        background-color: #262c2b;
        border: 3px solid #faff1c;
        width: 18px;
        height: 18px;
        margin-top: -7px;
      }
    }
    .pros-row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -6px;
      margin-right: -6px;
      .pros-item {
        padding-left: 6px;
        padding-right: 6px;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 12px;
        .pros-img {
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          overflow: hidden;
          position: relative;
          border-radius: 16px;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }
        }
      }
    }
    .project-available {
      h4 {
        font-weight: 700;
        font-size: 15px;
      }
      .nav-sidebar {
        .nav-item {
          border-bottom: 1px rgba(255, 255, 255, 0.25) solid;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
  .content-dashboard {
    width: calc(100% - 260px);
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 14px;
    color: #757575;
    label {
      font-size: 14px;
    }
    .dahboard-header {
      padding: 16px 25px;
      position: fixed;
      top: 0;
      right: 0;
      left: 260px;
      background: #2b4257;
      z-index: 99;
      h1 {
        margin: 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        color: #fff;
      }
      .mui-list--inline {
        display: flex;
        justify-content: flex-end;
        li {
          &:last-child {
            padding-right: 0;
          }
          a {
            border: 1px solid #c4c4c4;
            border-radius: 8px;
            display: flex;
            color: #757575;
            font-size: 16px;
            padding: 5px 8px;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 23px;
              }
              .img-arrow {
                height: 8px;
                margin-left: 10px;
              }
            }
            &:hover {
              background-color: #262c2b;
              color: #fff;
              border-color: #262c2b;
              span {
                img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                    hue-rotate(24deg) brightness(101%) contrast(101%);
                }
              }
            }
          }
        }
      }
    }
    .content-inner {
      padding: 80px 25px;
    }
    .dashboard-footer {
      margin-left: 25px;
      margin-right: 25px;
      padding: 15px 0;
      border-top: 1px rgba(0, 0, 0, 0.12) solid;
      a {
        color: #7c7c7c;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}

.dropdown {
  position: relative;
  .dropdown-list {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    padding: 5px 15px;
    right: 5px;
    position: absolute;
    .dropdown-item {
      font-size: 16px;
      padding-top: 7px;
      padding-bottom: 7px;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.progress {
  background: #f2f2f2;
  border-radius: 20px;
  overflow: hidden;
  .progress-val {
    background: #faff1c;
    height: 14px;
    border-radius: 20px;
  }
}

.btn-location {
  padding-left: 10px;
  padding-right: 10px;
}

.map-container {
  position: relative;
  .set-map {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.06));
    background: #fff;
    text-align: center;
    padding: 20px;
    max-width: 339px;
    p {
      color: #000000;
    }
  }
}

.work-tag {
  .btn {
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    span {
      position: absolute;
      right: 5px;
      top: 7px;
      cursor: pointer;
    }
    &.selected {
      background: rgba(196, 221, 128, 0.4);
      border-color: rgba(196, 221, 128, 0.4);
      padding-right: 27px;
      &:hover {
        color: #333333;
        img {
          filter: brightness(0) saturate(100%) invert(46%) sepia(14%) saturate(14%)
            hue-rotate(145deg) brightness(94%) contrast(80%);
        }
      }
    }
  }
}
.find-work {
  max-width: 290px;
  .form-control {
    height: 32px;
    border-radius: 8px;
  }
  .btn {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form-add-work {
  margin-top: 10px;
  max-width: 290px;
}

.spc-card {
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  overflow: hidden;
  .spc-top {
    position: relative;
    .spc-rate {
      position: absolute;
      right: 15px;
      top: 15px;
      span {
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    .spc-fav {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      position: absolute;
      cursor: pointer;
      bottom: -7px;
      right: 15px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .spc-desc {
    padding: 5px 15px;
    .spc-title {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #333333;
      margin-top: 0;
      margin-bottom: 4px;
      span {
        background: #b2ec15;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  .gallery-col {
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
  }
  .gallery-content {
    height: 0;
    padding-bottom: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    .img-gallery {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .add-photo {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #faff1c;
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: #2b4257;
      cursor: pointer;
    }
    .add-new-photo {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f2f2f2;
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .grey {
        filter: brightness(0) saturate(100%) invert(72%) sepia(2%) saturate(6%) hue-rotate(1deg)
          brightness(86%) contrast(86%);
      }
    }
    .add-video {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #f2f2f2;
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .video-gallery {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 99;
    }
  }
}

.gallery-detail {
  position: relative;
  .nav-item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
  }
  .gallery-inner {
    width: 70%;
    margin: auto;
    .img-containter {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
}

.modal {
  width: 587px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px 20px;
  &.modal-sm {
    width: 480px;
  }
  .modal-header {
    position: relative;
    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      margin: 0;
    }
    .modal-close {
      position: absolute;
      right: 0;
      top: 2px;
      cursor: pointer;
    }
  }
  .modal-content {
    color: #757575;
    padding-top: 15px;
    ol {
      padding-left: 14px;
      li {
        margin-bottom: 10px;
      }
    }
    .MuiFormControlLabel-root {
      justify-content: center;
    }
  }
}

.preference-list {
  padding: 16px 0;
  border-bottom: 1px solid #c4c4c4;
  &:first-child {
    border-top: 1px solid #c4c4c4;
  }
}

.MuiSwitch-track {
  background-color: #c4c4c4 !important;
  opacity: 0.5 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #c4c4c4 !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #faff1c !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #faff1c !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #faff1c !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
}
.css-j204z7-MuiFormControlLabel-root {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.allert-mm {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  padding-left: 20px;
  padding-right: 20px;
}

.card {
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.project-list {
  margin-top: 20px;
  overflow: hidden;
  .project-content {
    padding: 15px 20px;
    .project-title {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 18px !important;
      line-height: 27px;
      color: #333333;
      margin-top: 0;
    }
    p {
      color: #333333;
      font-size: 14px !important;
      line-height: 21px;
    }

    .project-description {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-break: break-word;
      -webkit-line-clamp: 4;
    }

    .project-meta {
      span {
        margin-right: 20px;
        display: inline-flex;
        font-size: 14px !important;
        img {
          margin-right: 5px;
        }
      }
    }
    .project-cat {
      display: flex;
      margin-top: 10px;
      flex-wrap: wrap;
      .project-cat-item {
        background: #f1f1f1;
        border-radius: 6px;
        padding: 8px 15px;
        color: #346c5c;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 14px !important;
      }
    }
  }
  .project-price {
    background: #f9f9f9;
    height: 100%;
    padding: 15px 20px;
    position: relative;
    .btn-circle {
      margin-left: auto;
    }
    .price-inner {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 30px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.ico-pps {
  flex: 0 0 30px;
  max-width: 30px;
  text-align: center;
}
.btn-circle {
  padding: 0;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zp--12 {
  max-width: 260px;
}

.detail-project {
  .MuiDrawer-paperAnchorRight {
    width: 80%;
    background: #f2f2f2 !important;
  }
}

.dw-container {
  .dw-header {
    background: #fff;
    padding: 15px;
  }
  .dw-content {
    padding: 15px;
    font-size: 14px;
  }
}

.card-img {
  border-radius: 8px;
  overflow: hidden;
  img {
    vertical-align: middle;
  }
}

.member-dsc {
  p {
    margin-bottom: 4px;
  }
}

.message-container {
  margin-right: -25px;
  margin-left: -25px;
  height: calc(100vh - 134px);
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: -81px;
  display: flex;
  .message {
    flex: 0 0 80%;
    max-width: 80%;
    border-bottom: 1px solid #c4c4c4;
  }
  .message-close {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.message {
  display: flex;
  background: #fff;
  height: 100%;
  .message-list {
    flex: 0 0 295px;
    max-width: 295px;
    border-right: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
    overflow-y: auto;
    .message-item {
      display: flex;
      position: relative;
      padding: 10px 25px;
      cursor: pointer;
      &.active {
        background: #f6f6f6;
      }
      .message-pp {
        width: 40px;
        height: 0;
        padding-bottom: 40px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .username {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
      .position {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #757575;
      }
      .message-count {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        background: #faff1c;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2b4257;
      }
    }
  }
  .message-content {
    border: 1px solid #c4c4c4;
    border-left: 0;
    border-bottom: 0;
    width: 100%;
    .message-header {
      display: flex;
      position: relative;
      padding: 10px 25px;
      align-items: center;
      border-bottom: 1px solid #c4c4c4;
      .message-pp {
        width: 40px;
        height: 0;
        padding-bottom: 40px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .username {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
      .position {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #757575;
      }
    }
    .message-body {
      padding: 10px 25px;
      height: calc(100% - 137px);
      overflow-y: auto;
      .message-date {
        text-align: center;
        span {
          display: inline-block;
          background: #f3f3f3;
          border-radius: 90px;
          font-size: 10px;
          color: #848484;
          padding: 5px 10px;
        }
      }
      .message-outgoing {
        text-align: right;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 20px;
        .message-inner {
          background: #97c13d;
          border-radius: 12px 12px 0px 12px;
          display: inline-block;
          color: #fff;
          padding: 15px;
        }
        .attachment {
          border-radius: 10px 10px 0px 10px;
        }
      }
      .message-incoming {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 20px;
        .message-inner {
          background: #f3f3f3;
          border-radius: 10px 10px 10px 0px;
          display: inline-block;
          color: #585858;
          padding: 15px;
        }
        .attachment {
          border-radius: 10px 10px 10px 0px;
        }
      }
      .message-time {
        font-size: 10px;
        margin-bottom: 0;
      }
      &.has-attachment > div:last-of-type {
        margin-bottom: 50px;
      }
      .attachment {
        width: fit-content;
        padding: 0 10px;
        position: relative;
        display: contents;
        s .topic {
          font-size: 12px;
          color: #848484;
          text-transform: capitalize;
          margin-bottom: 5px;
        }
        .title {
          font-size: 12px;
          color: #848484;
          margin-bottom: 5px;
        }
      }
    }
    .message-footer {
      padding: 10px 25px;
      position: relative;
      .message-input {
        display: flex;
        background: #f3f3f3;
        border-radius: 8px;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        .btn-inp--21 {
          padding-left: 5px;
          padding-right: 5px;
        }
        input {
          background: transparent;
          border: 0;
          width: 100%;
          padding-top: 5px;
          padding-bottom: 5px;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .message-attachment {
        height: 54px;
        width: calc(100% - 50px);
        overflow: hidden;
        background: #fff;
        position: absolute;
        top: -54px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
        .content {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          width: -webkit-fill-available;
          .topic {
            font-size: 12px;
            color: #848484;
            margin-bottom: 5px;
            text-transform: capitalize;
          }
          .title {
            font-size: 12px;
            color: #848484;
            margin-bottom: 5px;
          }
        }
        button {
          border-radius: 20px;
          height: 16px;
          width: 16px;
          padding: 0;
          font-size: 12px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.message-empty {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  max-width: 330px;
  margin: auto;
  padding: 50px 0;
}

.empty-project {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  max-width: 330px;
  margin: auto;
  padding: 50px 0;
}

.empty-users {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  padding: 50px 0;
}

.step {
  display: flex;
  justify-content: space-between;
  .step-item {
    position: relative;
    padding: 4px;
    background: #fff;
    font-weight: 600;
    &:before {
      position: absolute;
      content: '';
      width: 156px;
      height: 1px;
      background: #c4c4c4;
      right: -156px;
      top: 50%;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    .step-count {
      background: #c4c4c4;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
    }
    &.active {
      color: #faff1c;
      .step-count {
        background: #faff1c;
        color: #2b4257;
      }
      &:before {
        background: #faff1c;
      }
    }
  }
}

.file-container {
  display: flex;
  flex-wrap: wrap;
  .file-item {
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    .delete-file {
      position: absolute;
      top: -10px;
      right: -11px;
    }
  }
  .file-img {
    width: 120px;
    padding-bottom: 120px;
    height: 0;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.package {
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 100%;
  .package-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    h3 {
      margin: 0;
      text-transform: uppercase;
      color: #000000;
      font-size: 20px;
    }
  }
  .package-body {
    padding: 10px 20px;
    height: calc(100% - 200px);
    .package-list {
      .package-item {
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;
        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 2px;
          width: 15px;
          height: 15px;
          background: url(../../../../assets/tick.svg) center center/contain;
        }
        &.super {
          &::before {
            background: url(../../../../assets/flame.svg) center center/contain;
          }
        }
      }
    }
  }
  .package-footer {
    padding: 20px 20px;
    text-align: center;
    .package-price {
      font-weight: 400;
      font-size: 28px;
      line-height: 42px;
      color: #86ae18;
      margin-bottom: 15px;
    }
  }
}

.user-pp {
  position: relative;
  height: 0;
  width: 263px;
  padding-bottom: 263px;
  overflow: hidden;
  border-radius: 24px;
  .user-img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    font-size: 116px;
  }
  .user-fav {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.h-star {
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  &:first-child {
    padding-left: 20px;
  }
}

.rated {
  display: flex;
  align-items: center;
  .rated-l {
    text-align: center;
    .rated-t {
      font-family: 'Avenir', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 70px;
      line-height: 86px;
      color: #585858;
    }
    .rated-s {
      span {
        display: inline-block;
        padding-left: 4px;
        padding-right: 4px;
      }
    }
    .rated-cs {
      margin-top: 10px;
      font-size: 14px;
      line-height: 19px;
      color: #585858;
    }
  }
  .rated-rg {
    width: 490px;
    padding-left: 50px;
  }
  .pr-flex {
    display: flex;
    align-items: center;
  }

  .rt-t {
    font-family: 'Avenir', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #585858;
    width: 50px;
    padding-right: 5px;
  }
  .rt-pc {
    font-family: 'Avenir', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #585858;
    width: 40px;
    padding-left: 5px;
  }
}

.progress-rated {
  background: #f4f4f4;
  border-radius: 10px;
  height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  .progress-bar {
    border-radius: 10px;
    height: 100%;
    &.color-1 {
      background: #7cc8a3;
      width: 90%;
    }
    &.color-2 {
      background: #afd78c;
      width: 30%;
    }
    &.color-3 {
      background: #fed859;
      width: 20%;
    }
    &.color-4 {
      background: #feb253;
      width: 15%;
    }
    &.color-5 {
      background: #fd8c63;
      width: 10%;
    }
  }
}

.comment-box {
  display: flex;
  border-bottom: 1px solid #c4c4c4;
  align-items: center;
  .form-control {
    border: 0;
    padding-left: 0;
    &:focus {
      outline: none;
    }
  }
}

.comment-item {
  display: flex;
  margin-bottom: 20px;
  .item-l {
    flex: 0 0 180px;
    max-width: 180px;
  }
  .cm-tt {
    font-size: 17px;
    line-height: 23px;
    color: #585858;
    margin-bottom: 5px;
  }
  .cm-ct {
    font-size: 12px;
    line-height: 16px;
    color: #757575;
  }
  .cm-star {
    margin-bottom: 10px;
    span {
      margin-right: 8px;
    }
  }
  .cm-date {
    color: #8d8d8d;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  .pagination-item {
    padding: 3px;
    .page-link {
      background: #ededed;
      border-radius: 6px;
      padding: 5px;
      font-size: 16px;
      min-width: 20px;
      text-align: center;
      &.active {
        background: #faff1c;
        color: #2b4257;
      }
    }
  }
}

.payment-container {
  display: flex;
  height: 100%;
  .payment-content {
    width: 100%;
    padding: 40px;
  }
  .payment-sidebar {
    flex: 0 0 382px;
    max-width: 382px;
    background: #f9f9f9;
    padding: 40px;
    font-family: 'Poppins', sans-serif;

    h4 {
      font-size: 16px;
      color: #757575;
      font-weight: 600;
    }
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #757575;
    }
    .s-item {
      display: flex;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      h4 {
        margin-top: 0;
        margin-bottom: 7px;
      }
    }
  }
}

.payment-row {
  display: flex;
}

.mui-payment {
  justify-content: center;
  display: flex;
  .mui-col-payment {
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 46%;
    max-width: 46%;
  }
}

.payment-card {
  background: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.2));
  text-align: center;
  padding: 30px;
  .payment-text {
    font-weight: 700;
    font-size: 23px;
    line-height: 26px;
    color: #333333;
    padding-bottom: 20px;
  }
  .payment-price {
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    color: #43a047;
  }
  .payment-day {
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    margin-bottom: 0;
  }
  &:hover {
    background-color: #ebebeb;
  }
}
.payment-card.active {
  background-color: #ebebeb;
}
.payment-card.is-urgent {
  padding: 0 0 30px;
  .payment-text {
    background-color: #d32d2d;
    color: #fff;
    padding: 25px 0 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.stripe-input {
  padding-top: 13px;
}
.p-nav {
  display: flex;
  justify-content: center;
  .p-item {
    padding: 10px;
    .p-link {
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 16px;
      padding: 5px;
      cursor: pointer;
      img {
        filter: brightness(0) saturate(100%) invert(88%) sepia(0%) saturate(1341%)
          hue-rotate(147deg) brightness(98%) contrast(74%);
        vertical-align: middle;
      }
      &.active {
        background: #faff1c;
        border-color: #faff1c;
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(91%) saturate(38%)
            hue-rotate(254deg) brightness(110%) contrast(110%);
        }
      }
    }
  }
}

.visa-card {
  background: #f4f4f4;
  border-radius: 24px;
  padding: 5px 20px;
  label {
    color: #c4c4c4;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
