.chat-container {
  display: flex;
  .chat-list {
    flex: 0 0 295px;
    max-width: 295px;
  }
  .chat-detail {
    width: calc(100% - 295px);
  }
  .chat-item {
    display: flex;
    .chat-profile-img {
      flex: 0 0 40px;
      max-width: 40px;
    }
    .chat-username {
      padding-left: 10px;
      position: relative;
      width: calc(100% - 40px);
      .username {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
      }
      .role {
        font-weight: 400;
        font-size: 13px;
      }
      .chat-count {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background: #faff1c;
        color: #2b4257;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 11px;
        border-radius: 50%;
      }
    }
  }
}
