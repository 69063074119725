.header-search {
  border-bottom: 1px solid #c4c4c4;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.dashboard-job {
  padding-top: 40px;
}

.col-btn {
  flex: 0 0 129px;
  max-width: 128px;
  padding-left: 15px;
  .btn {
    padding: 13px 8px;
    width: 100%;
  }
}

.card-job {
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  margin-top: 20px;
  height: 200px;

  .job-image {
    flex: 0 0 207px;
    background-color: #2b4257;
    max-width: 207px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      background: url(../../../../assets/placeholder.jpg) no-repeat center center/cover;
    }
    .image {
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }
  }
  .job-body {
    width: 100%;
    padding: 21px 28px;
    .job-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #158bcd;
      margin-top: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      // white-space: nowrap;
      max-width: 800px;
      text-wrap: wrap;
    }
    .job-header {
      display: flex;
      justify-content: space-between;
      .job-act {
        flex: 0 0 250px;
        max-width: 250px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-primary {
          background: #2b4257;
          padding: 12px 34px;
        }
      }
    }
    .job-meta {
      display: flex;
      .meta-item {
        padding-right: 20px;
        display: flex;
        align-items: center;
        span {
          margin-left: 4px;
          // display: inline-block;
        }
      }
    }
    .job-content {
      word-break: break-word;
      max-width: 780px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
      margin-top: 10px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-break: break-word;
      -webkit-line-clamp: 4;
    }
  }
}
