@import 'homepage/scss/variable';

.MuiAppBar-colorPrimary {
  background-color: #2b4257 !important;
}

.top-container {
  margin: 20px 0px;
}

// Header
.toolbard-header-ionic {
  padding: 20px 50px !important;

  .link-top {
    padding: 5px 20px;
    font-family: $primaryFont;
  }

  .link-top.join-us {
    background-color: $yellow;
    color: $darkBlue;
    border-radius: 5px;
    margin: 0 20px;
    text-decoration: none;
  }
}

// end of Header
// Maincontent
.main-content {
  min-height: 77vh;
  font-family: $primaryFont !important;

  .MuiContainer-root {
    padding: 50px 0;
  }

  .fontsize-tnc {
    font-size: 10px;
  }

  .tnc-box {
    margin: 25px 0;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .almost-done {
    h1 {
      position: relative;
      text-align: left;
      padding-left: 5em;
      margin: 30px 0px 40px !important;

      .back {
        position: absolute;
        top: 3px;
        left: 0;
      }

      // &:before{
      //   content: ' ';
      //   display: block;
      //   background-image: url('../assets/arrow-left.svg');
      //   background-repeat: no-repeat;
      //   width: 40px;
      //   height: 40px;
      //   position: absolute;
      //   top: 0px;
      //   background-size: cover;
      //   left: 0;
      // }
    }
  }

  .user-type {
    .title {
      position: relative;
      text-align: center;
      padding: 13px;
      border: 1px solid $greyBorderUserType;
      border-radius: 10px;
      cursor: pointer;
      color: $greyInputLabel;
      font-size: 20px;

      &:hover,
      &.active {
        background-color: $lightGreen;
      }

      &:before {
        content: ' ';
        display: block;
        background-image: url('../assets/2-tier-contractor.svg');
        background-repeat: no-repeat;
        width: 33px;
        height: 33px;
        position: absolute;
        top: 8px;
        background-size: cover;
        left: 23px;
      }
    }

    .title.type-co {
      &:before {
        background-image: url('../assets/classified-only.svg');
      }
    }

    .title.type-gc {
      &:before {
        background-image: url('../assets/generator-contractor.svg');
      }
    }

    .title.type-sc {
      &:before {
        background-image: url('../assets/subcontractor.svg');
      }
    }

    .title.type-id {
      &:before {
        background-image: url('../assets/individule.svg');
      }
    }

    .title.type-vd {
      &:before {
        background-image: url('../assets/vendor.svg');
      }
    }
  }

  .login {
    .title {
      margin-bottom: 20px;

      h1 {
        margin-bottom: 10px;
        margin-top: 0;
        color: $solidBlack;
        font-size: 25px;
      }

      span {
        color: $greySecondaryText;
        font-size: 15px;
        display: inline-block;
      }
    }

    .forgot-password {
      margin: 20px 0;
    }

    .optional-login {
      position: relative;
      margin: 25px 0;

      &:before,
      &:after {
        content: ' ';
        display: block;
        border-bottom: 1px solid $mainDark;
        width: 45%;
        position: absolute;
        top: 13px;
      }

      &:after {
        right: 0px;
      }
    }
  }
}

// end of Maincontent

//form input

.form-input {
  .MuiTextField-root {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .MuiInputBase-formControl {
    font-family: $primaryFont;
    margin: 15px 0;
    border-radius: 10px;

    input {
      padding: 10px 15px 12px;
      font-size: 15px;
      margin-top: -5px;
    }

    fieldset {
      legend {
        display: none;
      }
    }

    &:hover {
      fieldset {
        border-color: $lightGreen !important;
      }
    }
  }

  .MuiInputBase-formControl.Mui-focused {
    fieldset {
      border-color: $lightGreen !important;
    }
  }

  .MuiInputLabel-outlined {
    color: $greyInputLabel;
    font-family: $primaryFont;
    font-size: 20px;
    left: -13px;
    top: -7px;
  }

  .MuiFormControlLabel-label {
    font-family: $primaryFont;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $lightGreen;
  }

  .MuiButton-fullWidth {
    padding: 10px 0;
    border-radius: 10px;
  }

  .MuiFormHelperText-contained {
    margin-left: 0px;
    margin-top: -7px;
  }
}

// end form input
// Footer
footer {
  display: block;

  .footer-container {
    padding: 0 50px;

    .link-footer {
      border-top: 1px solid $borderGray;
      padding: 25px 0px 60px 0;

      a {
        font-family: $primaryFont;
        margin: 0 15px;
        text-decoration: none;
        font-size: 15px;
        line-height: 18px;
        color: $greyFooterBottom;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

// end of Header
.text-underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.color-secondary-text {
  color: $greySecondaryText;
}

.color-main-dark {
  color: $mainDark;
}

.color-white {
  color: $mainWhite !important;
}

.main-dark {
  background-color: $darkBlue !important;
  color: $mainWhite !important;
}

.font-poppins {
  font-family: $primaryFont !important;
}

.main-white {
  background-color: $mainWhite !important;
  color: $greyInputLabel !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase;
}

.list-group {
  padding: 15px 0;
  border-top: 1px solid #c4c4c4;

  &:last-child {
    border-bottom: 1px solid #c4c4c4;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
  color: #333333;
}

.title-2 {
  font-weight: 600;
  font-size: 28px;
  color: #333333;
}

.workium-application {
  .version {
    position: fixed;
    bottom: 0px;
    padding: 15px 10px;
    text-transform: capitalize;
    right: 0;
    font-size: 12px;
  }
}

.MuiAutocomplete-inputRoot {
  .muioutlinedinput-root {
    padding: 8px 10px 12px !important;
  }

  .MuiAutocomplete-input {
    height: 12px;
  }
}

.text-black {
  color: #333333;
}
