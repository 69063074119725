@font-face {
  font-family: 'Poppins';
  src: url('../../../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../../../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$color-success: #9ab650;

*,
::after,
::before {
  box-sizing: border-box;
}

// html,
// body {
//   height: 100%;
// }

body {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
}

a:focus {
  outline: none;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}
.mb-3 {
  margin-bottom: 30px !important;
}
.mb-4 {
  margin-bottom: 40px !important;
}
.mb-5 {
  margin-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 10px !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.mt-3 {
  margin-top: 30px !important;
}
.mt-4 {
  margin-top: 40px !important;
}
.mt-5 {
  margin-top: 50px !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 10px !important;
}
.mr-2 {
  margin-right: 20px !important;
}
.mr-3 {
  margin-right: 30px !important;
}
.mr-4 {
  margin-right: 40px !important;
}
.mr-5 {
  margin-right: 50px !important;
}

.pr-1 {
  padding-right: 10px !important;
}
.pr-2 {
  padding-right: 20px !important;
}
.pr-3 {
  padding-right: 30px !important;
}
.pr-4 {
  padding-right: 40px !important;
}
.pr-5 {
  padding-right: 50px !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 10px !important;
}
.pl-2 {
  padding-left: 20px !important;
}
.pl-3 {
  padding-left: 30px !important;
}
.pl-4 {
  padding-left: 40px !important;
}
.pl-5 {
  padding-left: 50px !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 10px !important;
}
.pt-2 {
  padding-top: 20px !important;
}
.pt-3 {
  padding-top: 30px !important;
}
.pt-4 {
  padding-top: 40px !important;
}
.pt-5 {
  padding-top: 50px !important;
}

.m-0 {
  margin: 0 !important;
}

.img-fluid {
  max-width: 100%;
}

.text-12 {
  font-size: 12px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.form-control {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 0 15px 0 15px;
  font-size: 14px;
  background: #fff;
  &:not(textarea) {
    height: 45px;
  }
  &:focus-visible {
    outline-style: solid;
    outline-color: #faff1c;
  }
  &::placeholder {
    color: #c4c4c4;
  }
  &.form-control-sm {
    height: 32px;
  }
}
select.form-control {
  // padding-right: 30px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../../../assets/arrow-down.svg) no-repeat content-box right center;
  &::before {
    position: absolute;
    content: 'asd';
    background: url('../../../../assets/images/arrow-down.svg') no-repeat bottom center/contain;
    width: 30px;
    height: 30px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
textarea.form-control {
  padding-top: 15px;
  padding-bottom: 15px;
}
a {
  color: #61b19b;
}
a:hover {
  text-decoration: none;
}

label {
  margin-bottom: 10px;
  display: inline-block;
  font-size: 16px;
}

.w-100 {
  width: 100%;
}

.w-120 {
  width: 120px;
}

.h-120 {
  height: 120px;
}

.d-flex {
  display: flex;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.title {
  font-size: 36px;
  font-weight: bold;
  span {
    font-weight: normal;
    font-size: 20px;
  }
  @media screen and (max-width: 767.98px) {
    font-size: 23px;
    span {
      font-size: 14px;
    }
  }
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Nunito', sans-serif;
}

.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 25px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.btn {
  border-radius: 8px;
  padding: 10px 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  border: 0;
  background: transparent;
  cursor: pointer;
  .btn-icon {
    display: inline-block;
    img {
      vertical-align: sub;
    }
    &.unset {
      img {
        vertical-align: unset;
      }
    }
  }
}

.btn-outline-secondary {
  border: 1px solid #262c2b;
  border-radius: 8px;
  color: #262c2b;
  &:hover {
    background: #262c2b;
    color: #fff;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
        brightness(101%) contrast(101%);
    }
  }
}

.btn-outline-grey {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #757575;
  img {
    filter: brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(3119%) hue-rotate(189deg)
      brightness(87%) contrast(100%);
  }
  &:hover {
    background: #262c2b;
    color: #fff;
    border-color: #262c2b;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(24deg)
        brightness(101%) contrast(101%);
    }
  }
}
.btn-sm {
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

.btn-success {
  background: #faff1c;
  color: #2b4257;
  border: 1px #faff1c solid;
  &:hover {
    background: #eff30f;
    border-color: #eff30f;
  }
}

.btn-danger {
  background: #dd8080;
  color: #ffffff;
  border: 1px #dd8080 solid;
  &:hover {
    background: #b45a5a;
    border-color: #b45a5a;
  }
}

.btn-dark {
  background: #2b4257;
  color: #fff;
  border: 1px #2b4257 solid;
  &:hover {
    background: #000;
  }
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(56%) saturate(2%) hue-rotate(277deg)
      brightness(107%) contrast(101%);
  }
}

.btn-grey {
  background: #e0e1e1;
  color: #212121;
}

.btn-outline-dark {
  background: transparent;
  color: #262c2b;
  border: 1px #262c2b solid;
  &:hover {
    background: #262c2b;
    color: #fff;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(56%) saturate(2%) hue-rotate(277deg)
        brightness(107%) contrast(101%);
    }
  }
}

.btn-primary {
  color: #ffffff;
  background: #2b4257;
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  &:hover {
    background: #88a9c3;
  }
}

.wrapper-wwd {
  max-width: 315px;
  margin: 50px auto;
}

.subtitle-grey {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
}

.title-3 {
  font-weight: 500;
  font-size: 18px;
  color: #333333;
}

.token-input {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  input {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    height: 70px;
    &:focus-visible {
      outline-style: solid;
      outline-color: #faff1c;
    }
  }
}

.title-2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  font-family: 'Poppins', sans-serif;
}

.mmtt-2 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mmtt-3 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.upload-container {
  background: #f2f2f2;
  border: 1px solid #000;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  color: #9a9a9a;
  position: relative;
  width: 100%;
  height: 137px;
  justify-content: center;
  flex-direction: column;
  .button-upload {
    margin-top: 40px;
  }
  .view {
    position: relative;
    img {
      width: 100%;
      height: inherit;
      border-radius: 8px;
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .upload-icon {
    background: url(../../../../assets/images/camera.svg) no-repeat center center/contain;
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }
}

.title-small {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #c4c4c4;
}

.text-dark {
  color: #333333 !important;
}

.input-inline-group {
  position: relative;
  span {
    position: absolute;
    left: 0;
    display: inline-flex;
    top: 0;
    bottom: 0;
  }
  input {
    padding-left: 38px;
  }
  &.is-right {
    span {
      left: auto;
      right: 0;
    }
    input {
      padding-left: 15px;
      padding-right: 50px;
    }
  }
}

.text-danger {
  color: #e53935;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.ml-auto {
  margin-left: auto;
}

.label-outline {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  padding: 3px 10px;
  &.active {
    border-color: #faff1c;
    background: #faff1c;
    color: #262c2b;
  }
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border-radius: 8px !important ;
}

.css-187mznn-MuiSlider-root {
  color: #faff1c !important;
}

.text-14 {
  font-size: 14px !important;
}

.mui-row-sm {
  margin-left: -5px;
  margin-right: -5px;
  [class*='col'] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.sub {
  vertical-align: sub;
}
.middle {
  vertical-align: middle;
}

.card-body {
  padding: 20px;
}

.inline-block {
  display: inline-block;
}

.p-4 {
  padding: 40px;
}

.single-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
}

.self-end {
  align-self: flex-end;
}

.filter-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(199%) hue-rotate(16deg)
    brightness(114%) contrast(100%);
}

.ico-menu {
  flex: 0 0 20px;
  max-width: 20px;
  text-align: center;
}
.option {
  display: flex;
  flex-wrap: wrap;
  .option-item {
    padding: 3px 10px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    color: #757575;
    &.active {
      background: #e7f1cc;
      border-color: #e7f1cc;
      color: #262c2b;
    }
  }
}

// .MuiTypography-body1 {
//   font-family: 'Poppins', sans-serif !important;
//   font-size: 14px !important;
// }

.MuiCheckbox-root {
  color: #200e32 !important;
}

.text-grey {
  color: #757575;
}

.text-success {
  color: #9ab650;
}

.nav-tab {
  display: flex;
  border-bottom: 1px solid #c4c4c4;
  .nav-item {
    padding: 9px 10px;
    padding-bottom: 0;
    &:first-child {
      padding-left: 0;
    }
    .nav-link {
      display: inline-block;
      cursor: pointer;
      color: #757575;
      font-weight: 400;
      font-size: 16px;
      border-bottom: 3px solid transparent;
      padding-bottom: 9px;
      &.active {
        color: $color-success;
        border-bottom: 3px solid #faff1c;
      }
    }
  }
}

.h-100 {
  height: 100% !important;
}

.justify-center {
  justify-content: center;
}

.menu-dropdown-12 {
  background: transparent !important;
  box-shadow: none !important;
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 0;
    min-height: 0;
    .css-o4b71y-MuiAccordionSummary-content {
      margin: 0;
      color: #fff;
    }
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }
  .css-i4bv87-MuiSvgIcon-root {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
      brightness(102%) contrast(102%);
  }
  .css-15v22id-MuiAccordionDetails-root {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .submenu {
    list-style: none;
    padding: 0;
    li {
      border: 0 !important;
      color: rgba(250, 250, 250, 0.5) !important;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      font-size: 14px;
      &:last-child {
        padding-bottom: 0 !important;
      }
      &:hover {
        color: rgba(250, 250, 250, 1) !important;
      }
    }
  }
}

.map-content {
  height: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  .map-container-large {
    height: 100%;
  }
}

.absolute {
  position: absolute;
}
.bottom {
  vertical-align: bottom;
}
.top {
  vertical-align: top;
}

// .MuiSvgIcon-root {
//   fill: #c4dd80 !important;
// }

.css-a0yb10-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #333333 !important;
  border: 1px solid #faff1c !important;
  background-color: #faff1c !important;
}

.content-inner-wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 30px 15px;
  font-size: 14px;
}
.subtitle {
  font-weight: 500;
}

.content-inner {
  font-size: 14px;
}

.inner-padding {
  padding-top: 100px !important;
}

.subtitle-2 {
  font-weight: 600;
  font-size: 16px;
  color: #757575;
}

.list-icon {
  display: flex;
  padding: 20px 0;
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
  &:last-child {
    border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
  }
}

.list-icon__no_bottom_border {
  display: flex;
  padding: 20px 0;
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
}

.tag-label {
  background: rgba(196, 221, 128, 0.4);
  border-radius: 8px;
  padding: 4px 17px;
  color: #262c2b;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
  &.grey {
    background: #f1f1f1;
    color: #346c5c;
  }
}
.fav-btn {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: 0;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.css-5wcimd-MuiButtonBase-root-MuiTab-root {
  padding: 0 !important;
  margin-left: 0 !important;
}

// .Mui-selected {
//   color: #9ab650 !important;
// }

.css-1aquho2-MuiTabs-indicator {
  background: #9ab650 !important ;
}

.rounded-img {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

h3,
h2,
h4 {
  font-family: 'Poppins', sans-serif;
}

.uploads-container {
  width: 137px;
  padding-bottom: 137px;
  background: #f2f2f2;
  position: relative;
  overflow: hidden;
  height: 0;
  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 2;
  }
  .upload-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .uploads-label {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    font-size: 12px;
    color: #9a9a9a;
    padding: 20px;
  }
}

.form-profile {
  h4 {
    font-weight: 500;
    font-size: 18px;
  }
  h3 {
    font-weight: 600;
    font-size: 28px;
  }
}

.form-company-contact {
  max-width: 330px;
  margin: 0 auto;
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 20px;
  }
}

.check-btn {
  position: relative;
  label {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    padding: 4px 18px;
    color: #757575;
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
    &:checked ~ label {
      background: #000;
      color: #fff;
      border-color: #000;
    }
  }
}

.form-date {
  .MuiInputBase-input {
    padding: 12px 15px !important;
    font-size: 14px !important;
  }
  .MuiInputBase-root {
    border-radius: 8px !important;
  }
}

.text-11 {
  font-size: 11px;
}

.spacing-dollar {
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  margin-top: 59px;
}

.breadcrumb {
  display: flex;
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  .active {
    font-weight: 300;
    color: #8a8a8a;
  }
}

.job-applicant-list {
  padding: 10px 0;
  border-top: 1px solid #c4c4c4;
  &:last-child {
    border-bottom: 1px solid #c4c4c4;
  }
  .name {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    margin: 0;
  }
  .rate-title {
    font-weight: 500;
    margin-bottom: 3px;
  }
}

ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: '- ';
  text-indent: -5px;
}

.policy-page {
  p {
    margin-bottom: 20px;
  }
  p,
  li {
    color: #757575;
    margin-bottom: 5px;
  }
  ol {
    padding-left: 18px;
    &.line {
      li {
        border-bottom: 1px solid #232323;
      }
    }
  }
  ul.dashed {
    padding-left: 8px;
    margin-bottom: 20px;
  }
  ol.inside {
    list-style-position: inside;
    padding-left: 0;
    margin-bottom: 20px;
  }
}

.line-dark {
  background-color: #232323;
}

.featured {
  border: 3px #faff1c solid;
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    content: 'FEATURED';
    background: #faff1c;
    left: -38px;
    top: -12px;
    font-weight: 800;
    font-size: 12px;
    color: #2b4257;
    transform: rotate(-45.72deg);
    padding-top: 34px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 6px;
  }
}

.urgent {
  border: 3px #d32d2d solid;
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    content: 'URGENT';
    background: #d32d2d;
    left: -38px;
    top: -14px;
    font-weight: 800;
    font-size: 14px;
    color: #fff;
    transform: rotate(-45.72deg);
    padding-top: 34px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 6px;
  }
}

.card-price {
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  h3 {
    margin: 0;
    color: #000;
    font-weight: 500;
    font-size: 20px;
  }
  .card-price-heading {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-price-body {
    padding: 20px;
    padding-top: 0;
  }
  .card-price-footer {
    padding: 20px;
    padding-top: 0;
  }
  .price {
    font-weight: 500;
    font-size: 28px;
    color: #86ae18;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  .btn {
    padding-left: 50px;
    padding-right: 50px;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 23px;
      color: #333333;
      &::before {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        background: url(../../../../assets/checked.svg) no-repeat center center/contain;
        left: 0;
      }
    }
  }
  .select {
    position: relative;
    input {
      position: absolute;
      opacity: 0;
    }
    label {
      background: #2b4257;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      padding: 10px 50px;
      cursor: pointer;
    }
    input:checked ~ label {
      background: #faff1c;
      color: #2b4257;
    }
  }
}

.created-text {
  font-weight: 500;
  font-size: 18px;
  margin-top: 40px;
  color: #333333;
}

.list-price {
  background: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
  position: relative;
  input {
    position: absolute;
    opacity: 0;
  }
  label {
    padding: 24px;
    width: 100%;
    cursor: pointer;
    margin: 0;
  }
  .list-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
      font-weight: 500;
      font-size: 40px;
      color: #43a047;
    }
  }
  .label-name {
    padding-right: 25px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 17px;
      height: 17px;
      border: 1px solid #adadad;
      border-radius: 50%;
      right: 0;
      margin-top: 2px;
    }
    &::after {
      position: absolute;
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 50%;
      right: 4px;
      margin-top: 6px;
      background: transparent;
    }
  }
  input:checked ~ label .label-name::before {
    border-color: #43a047;
  }
  input:checked ~ label .label-name::after {
    background: #43a047;
  }
}

.job-ads {
  max-width: 450px;
  margin: 0 auto;
}

.divider-ads {
  border: 4px solid #faff1c;
  margin: 46px 0;
}

.total-ads {
  padding: 24px;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
  background: #ffffff;
  border-radius: 10px;
  .list-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
      font-weight: 500;
      font-size: 40px;
      color: #43a047;
    }
  }
  .label-name {
    padding-right: 15px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}

.ads-payment-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #333333;
}

.nav-payment {
  display: flex;
  justify-content: center;
  .item {
    border: 1px solid #c4c4c4;
    border-radius: 16px;
    width: 64px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    &.active {
      background: #faff1c;
      border-color: #faff1c;
      img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(164%) hue-rotate(105deg)
          brightness(116%) contrast(100%);
      }
    }
  }
}

.card-visa {
  background: #f4f4f4;
  border-radius: 24px;
  padding: 20px;
  input {
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
    border: 0;
    height: 44px;
  }
  label {
    font-size: 10px !important;
    color: #c4c4c4;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.btn-payment {
  width: 100%;
  max-width: 290px;
  margin: 0 auto;
  margin-top: 30px;
  display: block;
}

.rule-list {
  padding-left: 15px;
  li {
    margin-bottom: 20px;
  }
}
